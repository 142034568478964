<template>
    <div class="app-view">
        <v-container class="pt-0 mt-0">

            <div class="py-4">
                <h1>{{ $t("request_access.heading") }}</h1>
                <p class="my-0 py-0">{{ $t('request_access.subheading') }}</p>
            </div>

            <v-card flat v-if="!isSaved">
                <v-card-text>
                    <v-form class="py-4">
                        <v-select
                            :items="companies"
                            item-text="name"
                            item-value="id"
                            label="Choose company"
                            v-model="user.companyId"
                            :rules="[rules.required]"
                        ></v-select>

                        <v-select
                            :items="jobTitles"
                            :label="$t('request_access.job_title.label')"
                            v-model="user.jobTitle"
                            item-text="translated"
                            item-value="key"
                            :rules="[rules.required]"
                            @change="clearSelfInputJobtitle"
                        ></v-select>

                        <v-text-field
                            v-if="user.jobTitle === 'external_surveyor' || user.jobTitle === 'other'"
                            :label="$t('request_access.job_title.correct_title')"
                            v-model="selfInputJobtitle"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <!--<v-text-field
                            :rules="[rules.required]"
                            type="number"
                            label="IMO number"
                            v-model.number="user.imo"
                            hint="This is a 7 character number"
                            counter="7"
                        ></v-text-field>

                        <v-text-field
                            label="Vessel name"
                            v-model="user.ship"
                        ></v-text-field>-->

                        <v-text-field
                            :rules="[rules.required]"
                            :label="$t('basic.first_name')"
                            v-model="user.firstName"
                        ></v-text-field>

                        <v-text-field
                            :rules="[rules.required]"
                            :label="$t('basic.last_name')"
                            v-model="user.lastName"
                        ></v-text-field>

                        <v-text-field
                            :rules="[rules.required]"
                            type="email"
                            :label="$t('basic.email')"
                            v-model="user.email"
                        ></v-text-field>

                        <v-text-field
                            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                            :rules="[rules.required, rules.min]"
                            :type="showPassword ? 'text' : 'password'"
                            name="pw"
                            :label="$t('password.label')"
                            :hint="$t('password.hint')"
                            class="input-group--focused"
                            @click:append="showPassword = !showPassword"
                            v-model="user.password"
                        ></v-text-field>

                        <div class="text-center">
                            <v-btn color="primary" :loading="isLoading" depressed rounded outlined @click="signUpUser">
                                {{ $t('request_access.request_access_btn') }}
                            </v-btn>
                        </div>

                        <v-alert type="error" dense icon="error_outline" v-if="isError" class="mt-2">
                            {{ errorText }}
                        </v-alert>

                    </v-form>
                </v-card-text>
            </v-card>

            <div class="text-center pt-4" v-if="!isSaved">
                <router-link :to="{name: 'signin'}">
                    <v-btn color="primary" rounded depressed>
                        {{ $t('request_access.already_member') }}
                    </v-btn>
                </router-link>
            </div>

            <div class="text-xs-center helper-links" v-if="isSaved">

                <v-alert type="success" tile prominent dense class="mt-2">
                    <p>{{ $t('request_access.check_email') }}</p>
                    <p>{{ $t('request_access.email_not_found') }}</p>
                    <a :href="mailToLink">mail@surveyassociation.com</a>
                </v-alert>
            </div>

        </v-container>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {userSignUpMutation} from '../graphql/mutations/users';

export default {
    name: 'RequestAccess',
    data() {
        return {
            errorText: '',
            isLoading: false,
            isError: false,
            isSaved: null,
            companies: [],
            showPassword: false,
            rules: {
                required: value => !!value || this.$t('fields.general.rules.required'),
                min: v => (v && v.length >= 8) || this.$t('fields.general.rules.min', {min: 8})
            },
            selfInputJobtitle: '',
            user: {
                company: '',
                email: '',
                clientId: '',
                companyId: '',
                firstName: '',
                lastName: '',
                jobTitle: '',
                password: ''
            }
        };
    },
    computed: {
        ...mapState('client', ['currentClient']),
        jobTitles() {
            const titles = ['Captain', 'Chief officer', '2nd officer', '3rd officer', 'Chief engineer', '2nd engineer', '3rd engineer', 'Vessel Superintendent', 'External surveyor', 'Other'];
            return titles.map((title) => {
                const key = title.replace(/ /g, '_').toLowerCase();
                return {key: key, translated: this.$t(`request_access.job_title.${key}`)};
            });
        },
        mailToLink() {
            let queryParts = [];
            queryParts.push(`subject=Request access on ${window.location.origin}`);
            queryParts.push(`body=Hi, I am requesting access on ${window.location.origin} with the email: ${this.user.email}`);
            return `mailto:mail@surveyassociation.com?${queryParts.join("&")}`;
        }
    },
    methods: {
        clearSelfInputJobtitle() {
            this.selfInputJobtitle = '';
        },
        signUpUser() {
            this.isLoading = true;
            this.$apollo.mutate({
                mutation: userSignUpMutation,
                variables: {
                    email: this.user.email,
                    companyId: this.user.companyId,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    jobTitle: this.selfInputJobtitle.length > 0 ? this.selfInputJobtitle : this.user.jobTitle,
                    password: this.user.password,
                    languages: window.navigator.languages,
                    locale: this.$i18n.locale
                }
            }).then(data => {
                this.isLoading = false;
                this.isError = false;
                this.isSaved = true;
            }).catch(error => {
                let message = error.graphQLErrors[0]?.message || error;
                this.isLoading = false;
                if (message === 'Error: user.pendingUser') {
                    this.isSaved = true;
                } else {
                    this.isSaved = false;
                    this.errorText = message;
                    this.isError = true;
                }
            });
        }
    },
    created() {
        this.$store.dispatch('client/getAllCompanies').then((response) => {
            this.companies = response;
        });
    }
};
</script>
